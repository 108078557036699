export default function ShowSubMenuItems() {
    /**
     * Vérification de l'éxistence du menu et des sous-menus
     * ****************************************
     */
    const main = document.querySelector(
        ".main"
    ) as HTMLElement;

    const bgOpenSubmenu = document.querySelector('.bg-opensubmenu') as HTMLElement

    const menuItemsWrapper = document.querySelector(
        ".header__menu-main .menu__links"
    ) as HTMLElement;

    const menuItems = document.querySelectorAll(
        ".header__menu-main .menu__links>.menu__link"
    ) as NodeListOf<HTMLElement>;

    const menuItemsAsChild = document.querySelectorAll(
        ".header__menu-main .menu-item--expanded"
    ) as NodeListOf<HTMLElement>;

    const contactButton = document.querySelector(
        ".header__menu-main .button"
    ) as HTMLElement;


    /**
     * Rendre visible les sous-menus
     * ****************************************
     */
    if (window.innerWidth < 1440) {
        document.body.onclick = (e) => {
            menuItemsAsChild.forEach((menuItemAsChild) => {
                if (e.target === menuItemAsChild) {
                    menuItemAsChild.classList.add('active')
                    menuItemsWrapper.style.height = ((menuItemAsChild.querySelector('.sub-menu') as HTMLUListElement).getBoundingClientRect().height + 32) + 'px'
                    contactButton.classList.add('active')
                    menuItems.forEach((menuItem) => {
                        menuItem.classList.add('child-is-active');
                    })
                } else if (e.target === menuItemAsChild.querySelector('.text-link')) {
                    menuItemAsChild.classList.remove('active')
                    contactButton.classList.remove('active')
                    menuItemsWrapper.style.height = 'auto'
                    menuItems.forEach((menuItem) => {
                        menuItem.classList.remove('child-is-active');
                    })
                }
            });
        }
    } else {
        menuItemsAsChild.forEach((menuItemAsChild) => {
            const menuItemAsChildList = menuItemAsChild.querySelector('.sub-menu') as HTMLElement

            menuItemAsChild.onclick = () => {
                if (!menuItemAsChild.classList.contains('active')) {
                    menuItemsAsChild.forEach((el) => {
                        const elList = el.querySelector('.sub-menu') as HTMLElement
                        elList.classList.remove('active')
                    })

                    menuItemAsChildList.classList.add('active')
                    bgOpenSubmenu.classList.add('active')
                } else {
                    menuItemAsChildList.classList.remove('active')
                    bgOpenSubmenu.classList.remove('active')
                }
            }

            document.body.onclick = (e) => {
                const elem = e.target as HTMLElement;
                if (!elem.closest('.menu-item--expanded') && e.target !== menuItemAsChild) {
                    menuItemsAsChild.forEach((el) => {
                        const elList = el.querySelector('.sub-menu') as HTMLElement
                        elList.classList.remove('active')
                    })
                    bgOpenSubmenu.classList.remove('active')
                }
            }
        })
    }
}
